import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../../../components/Layout';
import HeadingDecorated from '../../../components/HeadingDecorated';
import TeamMember from '../../../components/TeamMember';
import Seo from '../../../components/Seo';
import arrowLeftImg from '../../../images/arrow-left.png';
import '../../../css/about.scss';

export const LOCALIZED_PAGES = {
  pl: '/o-nas/zespol/wspolpracownicy/',
  en: '/about-us/team/associates/',
};

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      fellows: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: { section: { eq: "fellows" } }
      ) {
        nodes {
          bio {
            data {
              bio
            }
          }
          email
          fullName
          jobTitle
          avatar {
            localFile {
              url
            }
          }
        }
      }
      stazysci: allStrapiTeamMember(
        sort: { fields: position, order: ASC }
        filter: { section: { eq: "stazysci" } }
      ) {
        nodes {
          bio {
            data {
              bio
            }
          }
          email
          fullName
          jobTitle
          avatar {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout localizedPages={LOCALIZED_PAGES}>
      <Seo title="O nas | Współpracownicy" />
      <div className="about">
        <section>
          <HeadingDecorated className="about__section-heading">
            Fellows
          </HeadingDecorated>
          <div className="about__section-content">
            {data.fellows.nodes.map((item) => (
              <TeamMember data={item} key={item.fullName} />
            ))}
          </div>
        </section>
        {data.stazysci.nodes.length > 0 && (
          <section>
            <HeadingDecorated className="about__section-heading">
              Stażyści
            </HeadingDecorated>
            <div className="about__section-content">
              {data.stazysci.nodes.map((item) => (
                <TeamMember data={item} key={item.fullName} />
              ))}
            </div>
          </section>
        )}
        <Link to="/o-nas/zespol/" className="about__footer-link">
          <img src={arrowLeftImg} alt="&larr;" />
          Zespół
        </Link>
      </div>
    </Layout>
  );
};

export default AboutPage;
